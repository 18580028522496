import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import { Section } from "./section";

const lineup: Array<{
  image: string;
  name: string;
  plays: string;
  link: string;
}> = [
  {
    image: "sven",
    name: "Sven Heinen",
    plays: "Bass",
    link: "https://instagr.am/inthekeyofjoy",
  },
  {
    image: "malin",
    name: "Malin Weber",
    plays: "Drums",
    link: "https://instagr.am/malin_returner",
  },
  {
    image: "leo",
    name: "Leo Bernard",
    plays: "Vocals & Keys",
    link: "https://instagr.am/leolabs2",
  },
  {
    image: "manuel",
    name: "Manuel Tesch",
    plays: "Guitar & Backing",
    link: "https://instagr.am/lumentasche",
  },
];

export const Lineup: React.FC = () => {
  const data = useStaticQuery(graphql`
    query Lineup {
      allFile(filter: { relativeDirectory: { eq: "lineup" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 1000, fit: COVER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `);

  const mappedData = data.allFile.nodes.reduce((acc: any, cur: any) => {
    acc[cur.name] = cur;
    return acc;
  }, {} as Record<string, any>);

  console.log(mappedData);

  return (
    <Section title="Lineup" className="grid grid-cols-4 gap-px bg-black">
      {lineup.map((n) => (
        <a href={n.link} key={n.link} className="relative">
          <Image
            fluid={mappedData[n.image].childImageSharp.fluid}
            alt={n.name}
          />
          <div
            style={{ background: "rgba(0,0,0,0.5)" }}
            className="absolute top-0 bottom-0 left-0 right-0 z-10 flex flex-col items-center justify-center px-3 py-2 text-center text-white transition duration-200 opacity-0 hover:opacity-100"
          >
            <span className="font-bold">{n.name}</span>
            <span>{n.plays}</span>
          </div>
        </a>
      ))}
    </Section>
  );
};
