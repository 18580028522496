import React, { FC } from "react";

import "../css/kit.css";
import { Section } from "./section";

export const KitForm: FC = () => {
  return (
    <Section className="p-4">
      <h2 className="text-white mb-4 text-xl">Subscribe to our Newsletter</h2>
      <form
        action="https://app.kit.com/forms/7597437/subscriptions"
        className="flex flex-wrap gap-4 items-center w-full"
        method="post"
      >
        <input
          aria-label="First Name"
          name="fields[first_name]"
          placeholder="First Name"
          className="flex-grow bg-white border-gray-500 rounded-md h-10 px-4"
          type="text"
        />
        <input
          name="email_address"
          aria-label="Email Address"
          placeholder="Email Address"
          className="flex-grow bg-white border-gray-500 rounded-md h-10 px-4"
          required
          type="email"
        />
        <button
          type="submit"
          className="bg-black text-white bg-opacity-75 backdrop-filter backdrop-blur h-10 rounded-md px-4"
        >
          <span className="">Subscribe</span>
        </button>
      </form>
    </Section>
  );
};
