import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { motion } from "framer-motion";

import "../css/style.css";

import { Songkick } from "../components/songkick";
import { Social } from "../components/social";
import { Section } from "../components/section";
import { Lineup } from "../components/lineup";
import { Link } from "gatsby";
import { Logo } from "../components/logo";
import { TgbofBackground } from "../components/tgbof-bg";
import { KitForm } from "../components/kit-form";

const VideoSection: React.FC<{ url: string; ratio?: string }> = ({
  url,
  ratio = "56.25%",
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 1000);
  }, [setIsLoaded]);

  return (
    <Section animate={isLoaded ? "show" : "hidden"}>
      <div
        style={{
          position: "relative",
          height: 0,
          overflow: "hidden",
          maxWidth: "100%",
          paddingBottom: ratio,
        }}
      >
        <iframe
          src={url}
          onLoad={() => setIsLoaded(true)}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          frameBorder="0"
          allowFullScreen
        />
      </div>
    </Section>
  );
};

export default () => (
  <>
    <Helmet>
      <title>Small Strides</title>
    </Helmet>

    <motion.div
      animate="show"
      initial="hidden"
      variants={{
        show: {
          transition: {
            staggerChildren: 0.1,
          },
        },
      }}
      className="container max-w-3xl px-4 pt-10 pb-10 mx-auto lg:pt-40"
    >
      <Logo />
      <Social />

      <VideoSection
        url="https://www.youtube.com/embed/videoseries?list=PLcxowIvVub-uyigAKVD7w0rm2e_xvX_5j"
        ratio="56.25%"
      />

      <Section title="About Us" className="px-5 py-4">
        <article>
          <h2 className="mb-2 text-xl">About Us</h2>
          <p className="mb-1">
            With their debut album{" "}
            <strong className="font-bold">“The Ground Beneath Our Feet”</strong>
            , released at the end of 2023, Small Strides transported the
            dramaturgy and emotional power of their concerts onto the LP and
            enthused both national and international voices.
          </p>

          <p className="mb-1">
            SCHALL magazine praised it as follows: “Ultimately, the whole album
            is a wonderfully self-contained island (...). Although tracks like
            'Your Arms' could also have been performed with the voice of the
            great David Bowie in his final years, they are perfect as they are
            now.”
          </p>

          <p className="mb-1">
            Tótum Revolútum added: “To end 2023 with a debut of this level and
            uniqueness is remarkable. Especially in a year where there has been
            a lot of quality music.”
          </p>

          <p className="mb-1">
            The “third space”, often declared dead, is experiencing a major
            renaissance in live music. Düsseldorf-based indiespheric band Small
            Strides don't just want to revive it, they want to turn it into a
            place of emotional connection and shared experience. Inevitably, the
            feedback at their concerts ranges from couples finally revealing
            their feelings, to intimate moments of friendship, as well as people
            coming to terms with personal crises and the way out of them.
          </p>

          <p className="mb-1">
            Small Strides' atmospheric songs are the catalysts that make these
            great evenings possible. The intoxicating soundscape of their indie
            rock, which they create with a dozen instruments and towering layers
            of live loops, becomes the foundation for personal topics such as
            mental health, self-doubt and fear of the future. Problems with
            which we are still too often alone in our everyday lives.
          </p>

          <p className="mb-1">
            From their own experience, however, the four Düsseldorfers are not
            satisfied with simple answers for their fans. Instead, they look at
            the heavy content of their songs through a prism of different
            perspectives to provide guidance and help. These changes of
            perspective are reflected in the music itself, which oscillates
            between haunting stadium anthems and sheltered ambient worlds.
          </p>

          <p className="mb-1">
            Rock Garage puts it in a nutshell: “Great emotions are not always
            easy to musically separate from kitsch. SMALL STRIDES' debut album
            manages this with flying colors. 'The Ground Beneath Our Feet' has
            become an outstanding, dense, intense and serious piece of music
            (...)”
          </p>

          <p className="mb-1">
            Following their debut album, whose release was celebrated at the
            legendary, pre-sold out Zakk in Düsseldorf, Small Strides are now
            taking the next leap in their musical journey. A journey on which
            they have long since ceased to be alone.
          </p>
        </article>
      </Section>

      <Lineup />

      <Songkick />

      <KitForm />
    </motion.div>

    <footer
      className="max-w-6xl mx-auto text-white"
      style={{
        backgroundImage:
          "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 100%)",
      }}
    >
      <div className="flex items-center justify-between max-w-3xl px-4 py-4 mx-auto text-shadow">
        <div>&copy; Small Strides {new Date().getFullYear()}</div>
        <div>
          <Link to="disclaimer" className="mr-4">
            Disclaimer
          </Link>
          <a href="https://drive.google.com/drive/folders/1V1E6Lmt35voTOpOAUaUQsh91mJSap_58?usp=sharing">
            Press Kit
          </a>
        </div>
      </div>
    </footer>

    <TgbofBackground />
  </>
);
